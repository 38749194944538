//vendors
import { connect } from "react-redux";
//components
import {AnnualSavings} from "../components/annual_savings";
//selectors
import {getCalcData} from "../selectors/app.selector";
//action


const mapStateToProps = (state: any) => ({
  annualSavings: getCalcData(state),
});

const mapDispatchToProps = (dispatch: any) => ({});

export const AnnualSavingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnualSavings);
