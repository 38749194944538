//vendors
import React from 'react';

//types
import {tImpactDetails} from "types/impact_details.type";

//styles
import classNames from "classnames";
import './impact_details.scss';
import {GreenArrowIcon} from "assets/images/icons/green_arrow";
import {RedArrowIcon} from "assets/images/icons/red_arrow";
import {ImpactDetailItem} from "../impact_detail_item";

type Props = {
	impactDetails: tImpactDetails
}

export const ImpactDetails = (props: Props) => {
	const data = [
		{
			name: "Time to provision a duplicate non-production database",
			withoutAccelario: props.impactDetails?.timeToProvisionDuplicatesNonProdDBs_withoutAcc,
			withAccelario: props.impactDetails?.timeToProvisionDuplicatesNonProdDBs_withAcc,
			change: props.impactDetails?.timeToProvisionDuplicatesNonProdDBs_changePercentage,
			format: "h"
		},
		{
			name: "Time to recover corrupted non-production databases",
			withoutAccelario: props.impactDetails?.timeToRecoverCorruptNonProdDBs_withoutAcc,
			withAccelario: props.impactDetails?.timeToRecoverCorruptNonProdDBs_withAcc,
			change: props.impactDetails?.timeToRecoverCorruptNonProdDBs_changePercentage,
			format: "h"
		},
		{
			name: "Time to recover corrupted non-production databases",
			withoutAccelario: props.impactDetails?.timeToRecoverCorruptNonProdDBs_withoutAcc,
			withAccelario: props.impactDetails?.timeToRecoverCorruptNonProdDBs_withAcc,
			change: props.impactDetails?.timeToRecoverCorruptNonProdDBs_changePercentage,
			format: "h"
		},
		{
			name: "Database storage footprint",
			withoutAccelario: props.impactDetails?.databaseStorageFootprint_withoutAcc,
			withAccelario: props.impactDetails?.databaseStorageFootprint_withAcc,
			change: props.impactDetails?.databaseStorageFootprint_changePercentage,
			format: "GB"
		},
		{
			name: "Time impact anonymizing data",
			withoutAccelario: props.impactDetails?.timeImpactAnonymizingData_withoutAcc,
			withAccelario: props.impactDetails?.timeImpactAnonymizingData_withAcc,
			change: props.impactDetails?.timeImpactAnonymizingData_changePercentage,
			format: "h"
		},
	]
		return (
			<>
				<div className={"mobile-impact-details"}>
					{data.map((item) => {
						return <ImpactDetailItem
							name={item.name}
							withoutAccelario={item.withoutAccelario}
							withAccelario={item.withAccelario}
							change={item.change}
							format={item.format}
						/>
					})}

					<div className="divider"></div>

					<div className="impact-detail-item-container">
						<div className="impact-detail-item-container__value">
							<div className="impact-detail-table-header">Without Accelario</div>
							<div className="impact-detail-table-content">{props.impactDetails?.totalAnnualTime_withoutAcc}h</div>
							<span className={classNames({"red": props.impactDetails?.totalAnnualTime_changePercentage <= 0})}>
								{props.impactDetails?.totalAnnualTime_changePercentage > 0 ? <GreenArrowIcon/> : <RedArrowIcon/>}
								{props.impactDetails?.totalAnnualTime_changePercentage}%
							</span>
						</div>
						<div className="impact-detail-item-container__value">
							<div className="impact-detail-table-header"></div>
							<div className="impact-detail-table-content">{props.impactDetails?.totalAnnualGb_withoutAcc}GB</div>
							<span className={classNames({"red": props.impactDetails?.totalAnnualGb_changePercentage <= 0})}>
								{props.impactDetails?.totalAnnualGb_changePercentage > 0 ? <GreenArrowIcon/> : <RedArrowIcon/>}
								{props.impactDetails?.totalAnnualGb_changePercentage}%
							</span>
						</div>
						<div className="impact-detail-item-container__value">
							<div className="impact-detail-table-header">With Accelario</div>
							<div className="impact-detail-table-content">{props.impactDetails?.totalAnnualTime_withAcc}h</div>
						</div>
						<div className="impact-detail-item-container__value">
							<div className="impact-detail-table-header"></div>
							<div className="impact-detail-table-content">{props.impactDetails?.totalAnnualGb_withAcc}GB</div>
						</div>
					</div>
				</div>

				<table className={"desktop-details-table"}>
					<thead>
					<th>Item</th>
					<th>Without Accelario</th>
					<th>With Accelario</th>
					<th>Change %</th>
					</thead>
					<tbody>
					{data.map((item) => {
						return <tr>
							<td>{item.name}</td>
							<td className="price">{item.withoutAccelario}{item.format}</td>
							<td className="price">{item.withAccelario}{item.format}</td>
							<td className="price">
							<span
								className={classNames({"red": item.change <= 0})}>
								{item.change > 0 ? <GreenArrowIcon/> :
									<RedArrowIcon/>}
								{item.change}%
							</span>
							</td>
						</tr>
					})}
					</tbody>
				</table>

				<div className="divider"></div>

				<table className="desktop-table-total">
					<tbody>
					<tr>
						<td className="table-total-title">Annual Total</td>
						<td className="price">
							{props.impactDetails?.totalAnnualTime_withoutAcc} h
							<br/>
							{props.impactDetails?.totalAnnualGb_withoutAcc} GB
						</td>
						<td className="price">
							{props.impactDetails?.totalAnnualTime_withAcc} h
							<br/>
							{props.impactDetails?.totalAnnualGb_withAcc} GB
						</td>
						<td className="price">
							<span className={classNames({"red": props.impactDetails?.totalAnnualTime_changePercentage <= 0})}>
								{props.impactDetails?.totalAnnualTime_changePercentage > 0 ? <GreenArrowIcon/> : <RedArrowIcon/>}
								{props.impactDetails?.totalAnnualTime_changePercentage}%
							</span>
							<br/>
							<span className={classNames({"red": props.impactDetails?.totalAnnualGb_changePercentage <= 0})}>
								{props.impactDetails?.totalAnnualGb_changePercentage > 0 ? <GreenArrowIcon/> : <RedArrowIcon/>}
								{props.impactDetails?.totalAnnualGb_changePercentage}%
							</span>
						</td>
					</tr>
					</tbody>
				</table>
			</>
		)
}