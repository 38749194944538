const hostName = window.location ? window.location.hostname : 'localhost';
const port = 8001;

const apiRootLocation = '//' + hostName + ':' + port + '/';

export default {
  appName: 'accelario-tdm-calculator',
  title: 'Accelario',
  endpoints: {
    frontend: apiRootLocation
  }
};
