//vendors
import { connect } from "react-redux";
//components
import {ImpactDetails} from "../components/impact_details";
//selectors
import {getCalcData} from "../selectors/app.selector";
//action


const mapStateToProps = (state: any) => ({
  impactDetails: getCalcData(state),
});

const mapDispatchToProps = (dispatch: any) => ({});

export const ImpactDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ImpactDetails);
