//vendors
import React from 'react';
import {GreenArrowIcon} from "assets/images/icons/green_arrow";
import {RedArrowIcon} from "assets/images/icons/red_arrow";
//styles
import classNames from "classnames";
import './impact_detail_item.scss';


type Props = {
	name: string
	withoutAccelario: number
	withAccelario: number
	change: number,
	format: string,
}

export const ImpactDetailItem = (props: Props) => {
		return (
			<div className="impact-detail-item-container">
				<div className="impact-detail-item-container__value">
					<div className="impact-detail-table-header">Name</div>
					<div className="impact-detail-table-title">{props.name}</div>
				</div>
				<div className="impact-detail-item-container__value">
					<div className="impact-detail-table-header">Without Accelario</div>
					<div className="impact-detail-table-content">{props.withoutAccelario}{props.format}</div>
				</div>
				<div className="impact-detail-item-container__value">
					<div className="impact-detail-table-header">With Accelario</div>
					<div className="impact-detail-table-content">{props.withAccelario}{props.format}</div>
					<span className={classNames({"red": props.change <= 0})}>
						{props.change > 0 ? <GreenArrowIcon/> : <RedArrowIcon/>}
						{props.change}%
					</span>
				</div>
			</div>
		)
}