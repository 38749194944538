import styles from './loader.module.scss';
import classNames from 'classnames';


type Props = {
	size?: 'small' | 'medium' | 'large'
}

export const Loader = (props: Props) => {
	return (
		<div className={classNames(
			styles.spinner,
			{[styles.small]: props.size === 'small'},
			{[styles.medium]: props.size === 'medium'},
			{[styles.large]: props.size === 'large'}
		)}></div>
	)
}

Loader.defaultProps = {
	size: 'medium'
}
