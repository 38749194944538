//vendors
import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {ToastContainer} from '@acc/notification';
//utils
import * as serviceWorker from './serviceWorker';
//components
import {AppContainer} from 'modules/main';
//styles
import './index.scss';
//store
import {configureStore} from 'store/configureStore';
//contexts

export const store = configureStore();

const container = document.getElementById('root') || document.createElement('div');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <AppContainer/>
    <ToastContainer bodyClassName="toastBody"/>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
