import React from 'react';

export const RedArrowIcon = (props: any) => (
  <svg width="7px"
       height="6px"
       viewBox="0 0 7 6"
       version="1.1"
       xmlns="http://www.w3.org/2000/svg"
       {...props}
  >
      <g fill="#BE3333" fillRule="nonzero">
        <path
          d="M2.5661132,0.41479925 C2.9652432,-0.13826475 3.7887432,-0.13826475 4.1878732,0.41478925 L6.5630932,3.70598925 C7.0403932,4.36735925 6.5678232,5.29119925 5.7522132,5.29119925 L1.0017732,5.29119925 C0.186167198,5.29119925 -0.286408802,4.36735925 0.190889198,3.70598925 L2.5661132,0.41479925 Z"
          transform="translate(3.377, 2.6456) scale(1, -1) translate(-3.377, -2.6456)">

        </path>
      </g>
  </svg>
);
