//vendors
import React from 'react';

//types
import {tAnnualSavings} from "types/impact_details.type";

//styles
import './annual_savings.scss';

type Props = {
	annualSavings: tAnnualSavings
}

export const AnnualSavings = (props: Props) => {
		return (
			<div className="savings-container">
				<div className="savings-item-header">
					<div className="savings-item-header-description">Your Annual Savings</div>
					<div className="savings-item-header-details">
						<div>Savings annually</div>
						<div>Financial Savings</div>
					</div>
				</div>
				<div className="savings-item">
					<div className="description">
						<div>Provisioning efficiency</div>
						<p>Time to provision a duplicate non-production database</p>
					</div>
					<div className="details">
						<div className="savings">
							<span>Savings annually</span>
							<span>{props.annualSavings.timeToProvisionDuplicatesNonProdDBs_change}h</span>
						</div>
						<div className="financial">
							<span>Financial Savings</span>
							<span>${props.annualSavings.timeToProvisionDuplicatesNonProdDBs_savingFinancial}</span>
						</div>
					</div>
				</div>
				<div className="savings-item">
					<div className="description">
						<div>Refresh speed</div>
						<p>Efficiency gains refreshing a non-production database</p>
					</div>
					<div className="details">
						<div className="savings">
							<span>Savings annually</span>
							<span>{props.annualSavings.timeToRefreshNonProdDBs_change}h</span>
						</div>
						<div className="financial">
							<span>Financial Savings</span>
							<span>${props.annualSavings.timeToRefreshNonProdDBs_savingFinancial}</span>
						</div>
					</div>
				</div>
				<div className="savings-item">
					<div className="description">
						<div>Recovery speed</div>
						<p>Improved recovery time restoring corrupted non-production databases</p>
					</div>
					<div className="details">
						<div className="savings">
							<span>Savings annually</span>
							<span>{props.annualSavings.timeToRecoverCorruptNonProdDBs_change}h</span>
						</div>
						<div className="financial">
							<span>Financial Savings</span>
							<span>${props.annualSavings.timeToRecoverCorruptNonProdDBs_savingFinancial}</span>
						</div>
					</div>
				</div>
				<div className="savings-item">
					<div className="description">
						<div>Storage savings</div>
						<p>Reduced storage footprint required</p>
					</div>
					<div className="details">
						<div className="savings">
							<span>Savings annually</span>
							<span>{props.annualSavings.databaseStorageFootprint_change}GB</span>
						</div>
						<div className="financial">
							<span>Financial Savings</span>
							<span>${props.annualSavings.databaseStorageFootprint_savingFinancial}</span>
						</div>
					</div>
				</div>
				<div className="savings-item">
					<div className="description">
						<div>Data anonymization speed</div>
						<p>Optimized time savings anonymizing data</p>
					</div>
					<div className="details">
						<div className="savings">
							<span>Savings annually</span>
							<span>{props.annualSavings.timeImpactAnonymizingData_change}h</span>
						</div>
						<div className="financial">
							<span>Financial Savings</span>
							<span>${props.annualSavings.timeImpactAnonymizingData_savingFinancial}</span>
						</div>
					</div>
				</div>
			</div>
		)
}
