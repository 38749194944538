//vendors
import axios from "axios";
import config from 'env';
import { apiResponseHandler } from "./api_response_handler";


const originalUrl = `${config.endpoints.frontend}/calc`;


export const CalcApi = {
  calcEfficiency: (data) => {
    const url = `${originalUrl}/efficiency`
    return axios
      .post(url, data)
      .then((response) => response.data)
      .catch((error) => apiResponseHandler(error))
  },
  calcEfficiencyPDF: (data) => {
    const url = `${originalUrl}/efficiency/pdf`
    return axios
      .post(url, data)
      .then((response) => response.data)
      .catch((error) => apiResponseHandler(error))
  },
};
