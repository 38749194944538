//vendors
import React from 'react';

//layouts
import styles from './header.module.scss';
import logo from "assets/images/logo.svg";

export const Header = () => {
		return (
			<header className={styles.header}>
				<a href="https://accelario.com/">
					<img src={logo} className={styles.header__logo} alt="logo"/>
				</a>
			</header>
	)
}