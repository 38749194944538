//vendors
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

//reducer
import { rootReducer } from 'rootReducer';

//saga
import { rootAppSaga } from 'rootSaga';

const reducer = require('rootReducer');

export const configureStore = (preloadedState?: any) => {
  const sagaMiddleware = createSagaMiddleware();

  // @ts-ignore
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );
  // @ts-ignore
  if (module.hot) {
    // @ts-ignore
    module.hot.accept('rootReducer', () => {
      store.replaceReducer(reducer)
    })
  }

  sagaMiddleware.run(rootAppSaga);

  return store
};
