import {useCallback, useEffect, useMemo, useState} from "react";

export type tInputInitialOptions = {
	label: string,
	name: string,
	required: boolean,
	type: "text" | "number" | "password"
}

export type tInputHook = {
	inputProps: {
		value: string | any,
		touched: boolean,
		error: string | undefined,
		onChange: (value: string | any) => void,
		onBlur: () => void
	} & tInputInitialOptions,
	value: string | any,
	touched: boolean,
	error: string | undefined
	setValue: (value: string | any) => void,
	setValueNumber: (val: string | number) => void,
	setError: (error: string | undefined) => void,
	setTouched: (value: boolean) => void,
	isValid: boolean
}

export function useInput(initialValue: string | any, options?: tInputInitialOptions): tInputHook {
	const [value, setV] = useState<string | any>(initialValue);
	const [touched, setTouched] = useState<boolean>(false);
	const [error, setError] = useState<string | undefined>(undefined);
	
	const validation = useCallback(() => {
		(options && options.label && !value.length)
			? setError(`Field "${options.label.replaceAll(":", "")}" is required`)
			: setError(undefined);
	}, [options, value]);
	
	useEffect(() => {validation()}, [validation]);
	
	const isValid = useMemo(() => !error, [error]);
	
	const setValueNumber = useCallback((val: string | number) => {
		if (Number.isInteger(Number(val)) || !val.toString().length) {
			setValue(val);
		}
	}, []);
	
	const touchedField = useCallback(() => {
		setTouched(true);
	}, [])
	
	const setValue = useCallback((val) => {
		setV(val);
	}, []);
	
	const inputProps = useMemo(() => {
		return {
			label: options?.label || "",
			name: options?.name || "input",
			required: options?.required || true,
			type: options?.type || "text",
			value: value,
			error: error,
			touched: touched,
			onChange: (options && options.type === "number") ? setValueNumber : setValue,
			onBlur: touchedField
		}
	}, [value, error, touched, options, setValue, setValueNumber]);
	
	return useMemo(() => ({
		inputProps, value, setValue, setValueNumber, touched, error, setTouched, setError, isValid
	}), [inputProps, value, setValue, setValueNumber, touched, error, setTouched, setError, isValid]);
}
