//vendors
import {takeEvery, put, call} from "redux-saga/effects";

//actions
import * as actions from '../actions';
import * as types from '../action_types';

//api
import { CalcApi } from 'api/calc.api';


export function* calcEfficiencySaga(action: { data, type: string }) {
  try {
    const response = yield call(CalcApi.calcEfficiency, action.data);
    yield put(actions.successCalcEfficiency(response));
  } catch (e) {
    yield put(actions.failCalcEfficiency(e.message));
  }
}

export function* calcEfficiencyPDFSaga(action: { data, type: string }) {
  try {
    const response = yield call(CalcApi.calcEfficiencyPDF, action.data);
    yield put(actions.successCalcEfficiency(response));
  } catch (e) {
    yield put(actions.failCalcEfficiency(e.message));
  }
}


export function* rootMainSaga() {
  yield takeEvery(types.CALC_EFFICIENCY_REQUESTED, calcEfficiencySaga);
  yield takeEvery(types.CALC_EFFICIENCY_PDF_REQUESTED, calcEfficiencyPDFSaga);
}
