import React from 'react';

export const GreenArrowIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="6"
    viewBox="0 0 8 6"
    fill="none"
    {...props}
  >
    <path
      d="M3.18912 1.1236C3.58825 0.570536 4.41175 0.570536 4.81088 1.12359L7.1861 4.41479C7.6634 5.07616 7.19083 6 6.37522 6H1.62478C0.809174 6 0.336598 5.07616 0.813896 4.41479L3.18912 1.1236Z"
      fill="#8BBE33"/>
  </svg>
);
