//action types
import * as types from '../action_types';
import { fromJS } from "immutable";
import {formatObjectNumbers} from "../../common/func";

export const initialState = fromJS({
  calcData: undefined,
  calcInProgress: false,
  hasError: false,
});

export const appReducer = (state: any = initialState, action: any ) => {
  switch (action.type) {
    case types.CALC_EFFICIENCY_REQUESTED:
    case types.CALC_EFFICIENCY_PDF_REQUESTED:
      return state
        .set("calcInProgress", true)
        .set("hasError", false)
        .set("calcData", null);
    case types.CALC_EFFICIENCY_SUCCEEDED:
      return state
        .set("calcInProgress", false)
        .set("calcData", formatObjectNumbers(action.data))
    case types.CALC_EFFICIENCY_FAILED:
      return state
        .set("hasError", true)
        .set("calcData", null)
        .set("calcInProgress", false)


    default:
      return state
  }
};
