import { createSelector } from 'reselect';

export const getCalcData = createSelector(
  [(state: any) => state.app],
  (app) => app.get("calcData")
);

export const getCalcInProgress = createSelector(
  [(state: any) => state.app],
  (app) => app.get("calcInProgress")
);

export const isHasError = createSelector(
  [(state: any) => state.app],
  (app) => app.get("hasError")
);