import * as types from '../action_types';

export const requestCalcEfficiency = (data) => ({
  type: types.CALC_EFFICIENCY_REQUESTED,
  data
});
export const successCalcEfficiency = (data) => ({
  type: types.CALC_EFFICIENCY_SUCCEEDED,
  data
});

export const failCalcEfficiency = (error) => ({
  type: types.CALC_EFFICIENCY_FAILED,
  error
});

export const requestCalcEfficiencyPDF = (data) => ({
  type: types.CALC_EFFICIENCY_PDF_REQUESTED,
  data
});

