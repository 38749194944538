//vendors
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
//components
import { App } from '../components/app';
//selectors
import {
  isHasError,
  getCalcInProgress, getCalcData
} from "../selectors/app.selector";
//action
import {
  requestCalcEfficiency,
  requestCalcEfficiencyPDF
} from "../actions";

const mapStateToProps = (state: any) => ({
  data: getCalcData(state),
  calculationInProgress: getCalcInProgress(state),
  hasError: isHasError(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  requestCalcEfficiency: bindActionCreators(requestCalcEfficiency, dispatch),
  requestCalcEfficiencyPDF: bindActionCreators(requestCalcEfficiencyPDF, dispatch),
});

export const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
